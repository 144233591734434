import {
  Button,
  Col,
  Divider,
  EditButton,
  Progress,
  Row,
  ShowButton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetLocale,
  useNavigation,
  useSetLocale,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { UserContext } from 'UserProvider';
import { Flex } from 'antd';
import { ClientType } from 'enums/client.type';
import { Firms } from 'enums/firms.type';
import { Languages } from 'enums/languages.type';
import { Role } from 'enums/role';
import { ShippingType } from 'enums/shipping.type';
import { IAgreement, ICompanies, IOffer, IShip } from 'interfaces';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ShipsShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const ids = id?.split('&')[0];

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [visibleStamp, setVisibleStamp] = useState<boolean>(
    me?.role === Role.ACCOUNTANT ? true : false,
  );

  const [hideBtn, setHideBtn] = useState<boolean>(true);

  const {
    queryResult: { data: response, isLoading, refetch: refetchOffer },
  } = useShow<IAgreement>({ resource: 'agreements/find', id: ids });

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (response?.data) {
  //     if (response?.data.language === Languages.RU) {
  //       console.log('ru', response?.data.language);
  //       changeLanguage('ru');
  //       // window.location.reload();
  //     } else {
  //       console.log('ro', response?.data.language);
  //       changeLanguage('ro');
  //       // window.location.reload();
  //     }
  //   }
  // }, [response]);
  if (!response) {
    return <Spin>Загрузка</Spin>;
  }
  const { data } = response;

  return !isLoading && data ? (
    <CanAccess resource="offers" action="show">
      <div
        style={{
          overflow: 'auto',
          paddingBottom: '70px',
        }}
      >
        <>
          <Row gutter={[20, 20]}>
            <Col>
              <Space size={12}>
                <Button
                  type="primary"
                  onClick={() => {
                    // save('printable');
                  }}
                >
                  Скачать заявку
                </Button>
                <CanAccess resource="ships" action="edit">
                  <EditButton type="primary" recordItemId={ids}>
                    Изменить
                  </EditButton>
                </CanAccess>
              </Space>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Progress size={180} />
                </div>
              ) : (
                <Flex>
                  <object
                    style={{ width: '100%', height: '85vh' }}
                    type="application/pdf"
                    data={`${process.env.REACT_APP_BACKEND_URL}/${data.pdf}?#zoom=85&scrollbar=1&toolbar=1&navpanes=1`}
                  ></object>
                </Flex>
              )}
            </Col>
          </Row>
        </>
      </div>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
